import { useAuthentication } from '@trr/app-shell-data'

interface UseGetRole {
  isMedarbetare: boolean
  roles: string[]
}

const useGetRole = (): UseGetRole => {
  const authentication = useAuthentication()
  const isMedarbetare = authentication?.userRoles?.includes('Medarbetare')

  return {
    isMedarbetare,
    roles: authentication?.userRoles ?? [],
  }
}

export default useGetRole
