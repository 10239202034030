import {
  useAddCandidateToListMutation,
  useCreateCandidateListMutation,
  useGetCandidateListsQuery,
} from '@local/AdvisorList'
import {
  useGetCandidateMatchingDataQuery,
  useGetCandidateUserDataQuery,
} from '@local/Profile/api/ProfileApi'
import { ProfileCallToAction } from '@local/Profile/components/ProfileCallToAction'
import { ProfileConsentSection } from '@local/Profile/components/ProfileConsentSection'
import { ProfileJobtitleSection } from '@local/Profile/components/ProfileJobtitleSection'
import { ProfileLocationSection } from '@local/Profile/components/ProfileLocationSection'
import { ProfileWorkTypeSection } from '@local/Profile/components/ProfileWorkTypeSection'
import { appRoutes } from '@local/Utils/Helpers'
import { usePickEpiContent, useSetBackButton } from '@local/Utils/Hooks'
import { CircularProgress, Divider, Stack } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { ProductPage } from '@local/Components/ProductPage/ProductPage'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

export const Profile = () => {
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const listId = urlParams.get('listId')
  const candidateId = urlParams.get('id')

  const { data: listData } = useGetCandidateListsQuery()
  const [addCandidate] = useAddCandidateToListMutation()
  const [createList] = useCreateCandidateListMutation()

  const {
    consentSection,
    jobtitleSection,
    locationSection,
    workTypeSection,
    callToAction,
  } = usePickEpiContent().profil

  const {
    data: matchingData,
    isLoading: isLoadingMatching,
    isError: isErrorMatching,
  } = useGetCandidateMatchingDataQuery(candidateId, {
    skip: candidateId === null,
  })
  const {
    data: userData,
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useGetCandidateUserDataQuery(candidateId, { skip: candidateId === null })
  const { isMobile } = useWindowSize()

  const hasAdditionalInformation =
    matchingData?.additionalInformation?.formsOfEmployment?.length > 0 ||
    matchingData?.additionalInformation?.extentsOfEmployment?.length > 0

  const baseUrl = '/medarbetare/matcha-kandidater/'

  useSetBackButton(
    `${baseUrl}${appRoutes.kandidatLista}?id=${listId}`,
    listId ?? ''
  )

  const userName = `${
    userData?.preferredFirstName
      ? userData?.preferredFirstName
      : userData?.firstName
  } ${userData?.lastName}`

  const candidate = {
    id: matchingData?.id,
    name: userName,
    jobTitles: matchingData?.jobTitles,
  }

  const linkedInUrl =
    userData?.socialMedia?.find((x) => x.type === 'linkedin')?.url ?? ''

  const handleAddToList = async (name: string, id: string) =>
    addCandidate({
      listName: name,
      listId: id,
      candidate: candidate,
    })

  const handleCreateList = async (name: string) =>
    createList({
      name: name,
      candidates: [candidate],
    })

  if (isLoadingMatching || isLoadingUser) {
    return <CircularProgress />
  }

  if (isErrorMatching || isErrorUser) {
    return (
      <EmptyResult
        heading={'Något gick fel'}
        description={'Kandidaten kan inte hittas, försök igen senare.'}
      />
    )
  }

  return (
    <ProductPage
      heading={userName}
      stickyElement={
        <>
          {isMobile && matchingData.consent && (
            <ProfileConsentSection sectionContent={consentSection} mb={4} />
          )}
          <ProfileCallToAction
            advisor={matchingData?.advisor}
            email={userData?.email}
            mobilePhone={userData?.mobilePhone}
            cvPdf={matchingData?.cvPdf}
            linkedInUrl={linkedInUrl}
            userId={userData?.id}
            userName={userName}
            content={callToAction}
            lists={listData}
            listId={listId}
            onAddToList={handleAddToList}
            onCreateList={handleCreateList}
          />
        </>
      }
    >
      <Stack spacing={3} mt={3} divider={<Divider />}>
        {!isMobile && matchingData.consent && (
          <ProfileConsentSection sectionContent={consentSection} />
        )}

        <ProfileJobtitleSection
          jobTitles={matchingData?.jobTitles ?? []}
          content={jobtitleSection}
        />

        <ProfileLocationSection
          content={locationSection}
          otherPreferences={
            matchingData?.additionalInformation?.otherPreferences
          }
          locations={matchingData.locationInformations}
        />

        {hasAdditionalInformation && (
          <ProfileWorkTypeSection
            content={workTypeSection}
            formsOfEmployment={
              matchingData?.additionalInformation?.formsOfEmployment
            }
            extentsOfEmployment={
              matchingData?.additionalInformation?.extentsOfEmployment
            }
          />
        )}
      </Stack>
    </ProductPage>
  )
}
