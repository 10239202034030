import { candidateListApi } from '@local/AdvisorList/api/CandidateListApi'
import { externalCandidateListApi } from '@local/ExternalCandidateList/api/externalCandidateList.api'
import { externalProfileApi } from '@local/ExternalProfile/api/externalProfile.api'
import { matchingServiceApi } from '@local/FindCandidate/Api/MatchingApi'
import { profileApi } from '@local/Profile/api/ProfileApi'
import { paramsToState } from '@local/Utils/Helpers'
import { candidateListSlice } from '@local/Utils/Helpers/candidateListSlice'
import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

const rootReducer = combineReducers({
  [matchingServiceApi.reducerPath]: matchingServiceApi.reducer,
  [candidateListApi.reducerPath]: candidateListApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [externalProfileApi.reducerPath]: externalProfileApi.reducer,
  [externalCandidateListApi.reducerPath]: externalCandidateListApi.reducer,
  paramsToState: paramsToState.reducer,
  candidateListSlice: candidateListSlice.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        matchingServiceApi.middleware,
        candidateListApi.middleware,
        profileApi.middleware,
        externalProfileApi.middleware,
        externalCandidateListApi.middleware,
      ]),
    preloadedState,
  })

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default store
