import {
  useActivateExternalMutation,
  useDeleteCandidateListMutation,
  useGetCandidateListByIdQuery,
} from '@local/AdvisorList/api/CandidateListApi'
import {
  appRoutes,
  candidateSuffix,
  removeAllCandidates,
  replaceEpiVariables,
} from '@local/Utils/Helpers'
import { useSetBackButton, usePickEpiContent } from '@local/Utils/Hooks'
import { CandidateListStatus } from '@local/AdvisorList/types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { EditCandidateListName } from '@local/CandidateList/components/EditCandidateListName/EditCandidateListName'
import { TableCandidateList } from '@local/CandidateList/components/TableCandidateList/TableCandidateList'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'
import {
  isExternallyAccessible,
  isValidCandidate,
} from '@local/CandidateList/components/Utils/candidateHelpers'
import ExternalLinkButton from '@local/CandidateList/components/ExternalLink/ExternalLinkButton'
import CreateGroupEmail from '@local/CandidateList/components/CreateGroupEmail/CreateGroupEmail'
import DeactivateExternalLinkButton from '@local/CandidateList/components/ExternalLink/DeactivateExternalLinkButton'
import CandidateListStatusChip from '@local/CandidateList/components/CandidateListStatusChip/CandidateListStatusChip'
import RemoveListIconButton from '@local/CandidateList/components/RemoveList/RemoveListIconButton'
import { date } from '@trr/frontend-datetime'
import { useDispatch } from 'react-redux'
import { useSearchQueryParams } from '@local/FindCandidate/Hooks/useSearchQueryParams'

import RemoveListDialog from '../RemoveListDialog/RemoveListDialog'
import InactiveCandidatesInfo from '../InactiveCandidatesInfo/InactiveCandidatesInfo'

export const CandidateList: React.FunctionComponent = () => {
  const [isRemoveModalActive, setIsRemoveModalActive] = useState(false)

  const { formattedSearchParams } = useSearchQueryParams()
  const history = useHistory()
  const baseUrl = '/medarbetare/matcha-kandidater/'

  useSetBackButton(`${baseUrl}${appRoutes.minaListor}`)
  const { emptyResult, metadata, externalLink, removeCandidateListDialog } =
    usePickEpiContent().candidateList
  const listId = new URLSearchParams(history.location.search).get('id')
  const page = formattedSearchParams.page
  const pageSize = formattedSearchParams.limit
  const {
    data: list,
    isLoading,
    isError,
  } = useGetCandidateListByIdQuery({ listId, page, pageSize })
  const [activateExternal] = useActivateExternalMutation()

  const [deleteList] = useDeleteCandidateListMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(removeAllCandidates())
  })

  if (isLoading && !list) {
    return <CircularProgress />
  }

  if (!list || isError) {
    return (
      <EmptyResult
        heading={emptyResult.title}
        description={emptyResult.body}
        sx={{ m: (theme) => theme.spacing(5, 2) }}
        action={{
          label: emptyResult.primaryButton,
          link: `${baseUrl}${appRoutes.minaListor}`,
        }}
      />
    )
  }

  const hasExternalLink = list.status === CandidateListStatus.Active
  const modifiedString = replaceEpiVariables(metadata?.modified, {
    date: date.format(list.modified, { style: 'numeric' }),
  })

  const hasInactiveCandidates = !list.candidates.every(isValidCandidate)
  const hasActiveCandidates = list.candidates.some(isValidCandidate)
  const hasExternallyAccessibleCandidates = list.candidates.some(
    isExternallyAccessible
  )

  const handleToggleRemoveModal = () => {
    setIsRemoveModalActive((prevState) => !prevState)
  }

  const handleGoToProfile = (id: string) => {
    history.push(`${baseUrl}${appRoutes.profil}?id=${id}&listId=${listId}`)
  }

  const handleDispatchRemoveList = () => {
    void deleteList(list.id)
    history.push(`${baseUrl}${appRoutes.minaListor}`)
  }

  const handleRemoveList =
    list.candidates.length > 0 || list.status === CandidateListStatus.Active
      ? handleToggleRemoveModal
      : handleDispatchRemoveList

  const handleDisableList = () => {
    void activateExternal({ listId: list.id, activate: false })
  }

  return (
    <Stack spacing={3} justifyContent="flex-start">
      <Typography variant="h1" sx={{ wordBreak: 'break-word' }}>
        {list.name}
      </Typography>

      {list.candidates.length > 0 && (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Stack spacing={1} direction={'row'}>
            <EditCandidateListName list={list} />
            {hasExternallyAccessibleCandidates && (
              <ExternalLinkButton
                variant={hasExternalLink ? 'copy' : 'activate'}
                list={list}
              />
            )}
            {hasActiveCandidates && <CreateGroupEmail list={list} />}
            <RemoveListIconButton onClick={handleToggleRemoveModal} />
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <CandidateListStatusChip
              status={list.status}
              expiryDate={list.activation?.expires}
              content={externalLink}
            />
            {hasExternalLink && <DeactivateExternalLinkButton list={list} />}
          </Stack>
        </Stack>
      )}

      <Stack spacing={2}>
        <Typography variant="h5">
          {candidateSuffix(list.totalValidCandidates)}
        </Typography>
        {hasInactiveCandidates && <InactiveCandidatesInfo />}

        {list.candidates.length > 0 ? (
          <TableCandidateList
            candidateList={list}
            metadata={{
              modified: modifiedString,
            }}
            onClick={handleGoToProfile}
          />
        ) : (
          <EmptyResult
            heading={emptyResult.title}
            description={emptyResult.body}
            action={{
              label: emptyResult.primaryButton,
              onClick: handleRemoveList,
            }}
            secondaryAction={
              list.status === CandidateListStatus.Active && {
                label: removeCandidateListDialog.disableButton,
                onClick: handleDisableList,
              }
            }
            sx={{ m: (theme) => theme.spacing(5, 2) }}
            data-testid="candidate-list-empty"
          />
        )}
      </Stack>

      {isRemoveModalActive && (
        <RemoveListDialog
          content={removeCandidateListDialog}
          listInfo={{
            name: list.name,
            numCandidates: list.totalValidCandidates,
            status: list.status,
          }}
          onToggle={handleToggleRemoveModal}
          onRemoveList={handleDispatchRemoveList}
        />
      )}
    </Stack>
  )
}
