import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { replaceEpiVariables } from '@local/Utils/Hooks/usePickEpiContent'

interface RemoveCandidateDialogProps {
  fullName: string
  onToggle: () => void
  onRemoveCandidate: () => void
}

const RemoveCandidateDialog = ({
  fullName,
  onToggle,
  onRemoveCandidate,
}: RemoveCandidateDialogProps) => {
  const { title, body, actionButton, cancelButton } =
    usePickEpiContent().candidateList.removeCandidateDialog

  return (
    <Dialog open onClose={onToggle}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {replaceEpiVariables(body, { candidate: fullName })}
        </Typography>
      </DialogContent>
      <DialogContent>
        <DialogActions>
          <Button onClick={onToggle} variant="text">
            {cancelButton}
          </Button>
          <Button
            onClick={onRemoveCandidate}
            data-testid="remove-candidate-dialog-confirm-button"
          >
            {actionButton}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default RemoveCandidateDialog
