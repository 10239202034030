import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { LocationAutocomplete } from '@local/FindCandidate/Components/LocationAutocomplete/LocationAutocomplete'
import { JobtitleInput } from '@local/FindCandidate/Components/JobtitleInput/JobtitleInput'
import IncludeCvContentCheckbox from '@local/FindCandidate/Components/IncludeCvContentCheckbox/IncludeCvContentCheckbox'
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import { JobFilter } from '@local/FindCandidate/Components/JobFilter/JobFilter'
import { CandidateList } from '@local/FindCandidate/Components/CandidateList/CandidateList'
import {
  SearchParamsComparison,
  defaultParamsComparison,
  useSearchQueryParams,
} from '@local/FindCandidate/Hooks/useSearchQueryParams'
import { equals } from 'ramda'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { DeepLocation } from '@local/Types/MatchingApi.types'
import { useDispatch } from 'react-redux'
import { removeAllCandidates } from '@local/Utils/Helpers'
import { useGetMatchesQuery } from '@local/FindCandidate/Api/MatchingApi'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

export const FindCandidate = () => {
  const rowsPerPageOptions = [20, 50, 100]
  const filterBarRef = useRef<HTMLDivElement>(null)
  const currentFilters = useRef({})

  const dispatch = useDispatch()
  const {
    searchParams,
    setSearchParams,
    setTextSearchField,
    formattedSearchParams,
  } = useSearchQueryParams()
  const rowsPerPage = formattedSearchParams.limit ?? rowsPerPageOptions[0]

  const { data: candidateResponse, isLoading } = useGetMatchesQuery({
    jobTitles: [],
    jobTitlesFreeText: formattedSearchParams.jobTitlesFreeText,
    filters: formattedSearchParams.filters,
    locations: formattedSearchParams.locations,
    onlyPublishedCandidates: false,
    page: formattedSearchParams.page,
    limit: rowsPerPage,
    currentFacet: formattedSearchParams.currentFacet,
    currentFilters: currentFilters.current,
    includeCvContent: formattedSearchParams.includeCvContent,
  })
  const {
    sokKandidatNy: { searchFiltering },
  } = usePickEpiContent()

  useEffect(() => {
    currentFilters.current = candidateResponse?.facets
  }, [candidateResponse])

  useLayoutEffect(() => {
    const queryParamsFromSessionStorage = JSON.parse(
      sessionStorage.getItem('sokKandidat-savedParams')
    )

    if (
      queryParamsFromSessionStorage !== null &&
      equals(defaultParamsComparison, searchParams)
    ) {
      setSearchParams(
        queryParamsFromSessionStorage as SearchParamsComparison,
        'replace'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLocationChange = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      newLocations: DeepLocation[]
    ) => {
      const locations = newLocations.map((loc) => {
        const typeText = loc?.type ? `,${loc.type}` : ''
        return `${loc.name}${typeText}`?.replace(/(<([^>]+)>)/gi, '')
      })
      setTextSearchField(locations, 'locations')
      dispatch(removeAllCandidates())
    },
    [dispatch, setTextSearchField]
  )

  const handleFreeTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newFreeTextStrings: string[]) => {
      setTextSearchField(newFreeTextStrings, 'searchTerms')
      dispatch(removeAllCandidates())
    },
    [dispatch, setTextSearchField]
  )

  const handleIncludeCvContentChange = useCallback(
    (checked: boolean) => {
      setSearchParams(
        {
          searchTerms: searchParams.searchTerms,
          locations: searchParams.locations,
          includeCvContent: checked,
          page: 1,
        },
        'replace'
      )
    },
    [setSearchParams, searchParams]
  )

  const scrollToFilterBar = () => {
    if (!filterBarRef?.current) return

    const rect = filterBarRef.current.getBoundingClientRect()
    if (rect.top < 0 || rect.bottom > window.innerHeight) {
      filterBarRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }

  useEffect(() => {
    scrollToFilterBar()
  }, [formattedSearchParams.page, formattedSearchParams.limit])

  const { isDesktop } = useWindowSize()

  return (
    <Container disableGutters style={{ marginLeft: 0 }} maxWidth="md">
      <Stack spacing={2}>
        <LocationAutocomplete
          locations={formattedSearchParams.locations}
          onChange={handleLocationChange}
        />
        <JobtitleInput
          values={formattedSearchParams.jobTitlesFreeText}
          onInputChange={handleFreeTextChange}
        />
        <IncludeCvContentCheckbox
          checked={formattedSearchParams.includeCvContent}
          onChange={handleIncludeCvContentChange}
        />
        {!isLoading && (
          <JobFilter options={candidateResponse?.facets} ref={filterBarRef} />
        )}
      </Stack>
      {isLoading ? (
        <div style={{ padding: '1rem' }}>
          <CircularProgress />
        </div>
      ) : candidateResponse?.matches.length > 0 ? (
        <>
          {isDesktop && (
            <Typography sx={{ mb: '1rem', mt: '2rem' }}>
              {searchFiltering.totalHitsText}: {candidateResponse?.totalHits}
            </Typography>
          )}
          <CandidateList
            candidates={candidateResponse?.matches}
            totalCount={candidateResponse?.totalHits ?? 0}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
          />
        </>
      ) : (
        <Box>
          <EmptyResult
            heading={searchFiltering.emptyStateTitle}
            description={searchFiltering.emptyStateText}
          />
        </Box>
      )}
    </Container>
  )
}
