import React, { useState } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { Email as EmailIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import CreateGroupEmailDialog from '@local/CandidateList/components/CreateGroupEmail/CreateGroupEmailDialog'
import { useCreateEmailDraftMutation } from '@local/AdvisorList'
import { CandidateList } from '@local/Types/MatchingApi.types'

interface CreateGroupEmailProps {
  list: Pick<CandidateList, 'name' | 'id' | 'mostRecentEmailDraftCreated'>
}

const CreateGroupEmail: React.FC<CreateGroupEmailProps> = ({ list }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { contactTooltip } = usePickEpiContent().candidateList.externalLink

  const [createEmailDraft] = useCreateEmailDraftMutation()

  const handleToggleCreateEmailDialog = () => {
    setIsDialogOpen((prevState) => !prevState)
  }

  const handleCreateEmail = (subject: string) => {
    void createEmailDraft({ listId: list.id, subject })
    setIsDialogOpen(false)
  }

  return (
    <>
      <Tooltip title={contactTooltip}>
        <IconButton
          onClick={handleToggleCreateEmailDialog}
          data-testid="create-group-email-button"
        >
          <EmailIcon />
        </IconButton>
      </Tooltip>
      {isDialogOpen && (
        <CreateGroupEmailDialog
          listName={list.name}
          mostRecentDraft={list.mostRecentEmailDraftCreated}
          onToggle={handleToggleCreateEmailDialog}
          onSubmit={handleCreateEmail}
        />
      )}
    </>
  )
}

export default CreateGroupEmail
