import { usePickEpiContent } from '@local/Utils/Hooks'
import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { replaceEpiVariables } from '@local/Utils/Hooks/usePickEpiContent'

interface ExternalLinkDialogProps {
  listName: string
  type: 'activate' | 'deactivate'
  onCancel: () => void
  onConfirm: () => void
}

const ExternalLinkDialog: React.FunctionComponent<ExternalLinkDialogProps> = ({
  listName,
  type,
  onCancel,
  onConfirm,
}) => {
  const shouldActivate = type === 'activate'
  const {
    titleCreate,
    titleDeactivate,
    block1,
    block2,
    block3,
    deactivateText1,
    deactivateText2,
    cancelButton,
    createButton,
    deactivateButton,
  } = usePickEpiContent().candidateList.externalListDialog

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>
        {shouldActivate
          ? replaceEpiVariables(titleCreate, { listName })
          : titleDeactivate}
      </DialogTitle>
      <DialogContent>
        {shouldActivate ? (
          <Stack spacing={3}>
            <Typography variant="body1">
              {replaceEpiVariables(block1, { listName })}
            </Typography>
            <Typography variant="body1">{block2}</Typography>
            <Typography variant="body1">{block3}</Typography>
          </Stack>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              {replaceEpiVariables(deactivateText1, { listName })}
            </Typography>
            <Typography variant="body1">{deactivateText2}</Typography>
          </>
        )}
      </DialogContent>
      <DialogContent>
        <DialogActions>
          <Button
            onClick={onCancel}
            variant="text"
            data-testid={'dialog-cancel-button'}
          >
            {cancelButton}
          </Button>
          <Button onClick={onConfirm} data-testid={'dialog-confirm-button'}>
            {shouldActivate ? createButton : deactivateButton}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default ExternalLinkDialog
