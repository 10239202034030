import { usePickEpiContent } from '@local/Utils/Hooks'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
  colors,
} from '@mui/material'
import React from 'react'

export interface Option {
  key: string
  title: string
  count: number
}

interface FilterOptionProps {
  subtitle?: string
  options: Option[]
  selectedFilters: string[]
  buttonProps?: ListItemButtonProps
  onChangeFilter: (key: string) => void
}

export const FilterOption = ({
  subtitle,
  options,
  selectedFilters,
  buttonProps,
  onChangeFilter,
}: FilterOptionProps) => {
  const { isDesktop } = useWindowSize()
  const { noFiltersText } = usePickEpiContent().sokKandidatNy.searchFiltering

  const isChecked = (key: string) =>
    selectedFilters?.some((selectedFilter) => selectedFilter === key)

  return (
    <List sx={{ paddingBottom: '.5rem' }} disablePadding>
      {subtitle && (
        <Typography
          sx={{
            padding: isDesktop ? '0 0 0 .7rem' : 0,
            marginBottom: '.5rem',
            color: colors.grey[600],
          }}
          variant="subtitle2"
          gutterBottom
        >
          {subtitle}
        </Typography>
      )}
      {options?.length === 0 && (
        <Typography variant="subtitle2">{noFiltersText}</Typography>
      )}
      {options?.map((option: Option) => (
        <ListItem
          key={option.key}
          secondaryAction={
            <Typography
              variant="body1"
              sx={{
                color: option.count > 0 ? colors.grey[600] : colors.grey[400],
              }}
            >
              {option.count}
            </Typography>
          }
          disablePadding
          divider={false}
        >
          <ListItemButton
            role="button"
            sx={{ paddingTop: 0, paddingBottom: 0, left: -5 }}
            onClick={() => onChangeFilter(option.key)}
            dense
            disabled={option.count === 0 && !isChecked(option.key)}
            {...buttonProps}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                edge="start"
                checked={selectedFilters?.some(
                  (selectedFilter) => selectedFilter === option.key
                )}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': option.title }}
              />
            </ListItemIcon>
            <ListItemText id={option.key} primary={option.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
