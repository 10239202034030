import {
  useAddCandidatesToListMutation,
  useCreateCandidateListMutation,
  useGetCandidateListsQuery,
} from '@local/AdvisorList'
import { CandidateListsApiModel } from '@local/AdvisorList/types'
import { AddCandidateDialog } from '@local/FindCandidate/Components/AddCandidateDialog/AddCandidateDialog'
import { CustomPopper } from '@local/FindCandidate/Components/CustomPopper/CustomPopper'
import { RootState } from '@local/Store/configureStore'
import { removeAllCandidates, replaceEpiVariables } from '@local/Utils/Helpers'
import { mapMatchToCandidate } from '@local/Utils/Helpers/typeHelper'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'
import { Assignment, Close } from '@mui/icons-material'
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface CandidateTableHeadProps {
  numSelected: number
  totalNumSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  totalCount: number
  paginationComponent?: React.ReactNode
}

const NonBreakingSpan = styled('span')(() => ({
  alignItems: 'center',
  bottom: 0,
  width: 'max-content',
  height: '100%',
  whiteSpace: 'nowrap',
}))

const StyledListItem = styled(ListItem)(() => ({
  border: 'none',
}))

export const CandidateTableHead = ({
  onSelectAllClick,
  numSelected,
  totalNumSelected,
  totalCount,
  rowCount,
  paginationComponent,
}: CandidateTableHeadProps) => {
  const candidateListButton = useRef(null)
  const [candidateListOpen, setCandidateListOpen] = useState(false)
  const [addCandidateDialogOpen, setAddCandidateDialogOpen] = useState(false)
  const { isDesktop } = useWindowSize()
  const {
    sokKandidatNy: { addToCandidateList, searchFiltering },
  } = usePickEpiContent()

  const openCandidateListOptions = () => {
    setCandidateListOpen(!candidateListOpen)
  }

  const { data } = useGetCandidateListsQuery()
  const selectedCandidates = useSelector(
    (state: RootState) => state.candidateListSlice.selectedCandidates
  )
  const [addCandidates] = useAddCandidatesToListMutation()
  const [createList] = useCreateCandidateListMutation()
  const dispatch = useDispatch()

  const isAllSelected = () => rowCount > 0 && numSelected === rowCount
  const isAnythingSelected = () => totalNumSelected > 0

  const handleCreateNewList = (listName: string) => {
    void createList({
      name: listName,
      candidates: selectedCandidates.map((candidate) =>
        mapMatchToCandidate(candidate)
      ),
    }).then(() => {
      dispatch(removeAllCandidates())
      setCandidateListOpen(false)
    })
  }

  const handleAddToList = (candidateList: CandidateListsApiModel) => {
    void addCandidates({
      listId: candidateList.id,
      listName: candidateList.name,
      candidates: selectedCandidates.map((candidate) =>
        mapMatchToCandidate(candidate)
      ),
    }).then(() => {
      dispatch(removeAllCandidates())
      setCandidateListOpen(false)
    })
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" component="th">
          <Checkbox
            color="primary"
            indeterminate={isAnythingSelected() && !isAllSelected()}
            checked={isAllSelected()}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Välj alla kandidater',
            }}
          />
        </TableCell>
        <TableCell
          key={'name'}
          align="left"
          padding="normal"
          sx={{
            position: 'relative',
            zIndex: 1,
          }}
        >
          {totalNumSelected === 0 && (
            <NonBreakingSpan
              sx={isDesktop && { position: 'absolute', display: 'flex' }}
            >
              {isDesktop ? `Markera alla ${rowCount}` : `Markera ${rowCount}`}
            </NonBreakingSpan>
          )}
          {totalNumSelected > 0 && (
            <NonBreakingSpan
              sx={
                isDesktop && {
                  position: 'absolute',
                  display: 'flex',
                }
              }
            >
              {isDesktop
                ? replaceEpiVariables(searchFiltering.selectionText, {
                    totalCount: totalCount.toString(),
                    selectedCount: totalNumSelected.toString(),
                  })
                : replaceEpiVariables(searchFiltering.selectionShortText, {
                    totalCount: totalCount.toString(),
                    selectedCount: totalNumSelected.toString(),
                  })}
              <IconButton
                onClick={openCandidateListOptions}
                data-testid="open-dialog--add-to-candidate-list-button"
              >
                <Assignment ref={candidateListButton} />
              </IconButton>
              <CustomPopper
                label={addToCandidateList.addToList}
                headerIcon={<Close />}
                onClickHeaderIcon={() => setCandidateListOpen(false)}
                open={candidateListOpen}
                anchor={candidateListButton.current}
                onClickAway={() => {
                  setCandidateListOpen(false)
                }}
                data-testid="add-to-candidate-list"
              >
                <List disablePadding>
                  <StyledListItem disablePadding>
                    <ListItemButton
                      onClick={() => setAddCandidateDialogOpen(true)}
                    >
                      {addToCandidateList.addToNewList}
                    </ListItemButton>
                    <AddCandidateDialog
                      open={addCandidateDialogOpen}
                      content={addToCandidateList}
                      handleClose={() => setAddCandidateDialogOpen(false)}
                      handleCreate={(listName) => handleCreateNewList(listName)}
                    />
                  </StyledListItem>
                  {data.map((candidateList) => (
                    <StyledListItem key={candidateList.id} disablePadding>
                      <ListItemButton
                        onClick={() => handleAddToList(candidateList)}
                      >
                        {candidateList.name}
                      </ListItemButton>
                    </StyledListItem>
                  ))}
                </List>
              </CustomPopper>
            </NonBreakingSpan>
          )}
        </TableCell>
        <TableCell
          key={'jobtitles'}
          align="right"
          padding="none"
          sx={{ whiteSpace: 'pre' }}
        >
          {paginationComponent}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
