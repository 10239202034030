import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'

interface EditCandidateListNameDialog {
  name: string
  onToggle: () => void
  onSubmit: (name: string) => void
}

export const EditCandidateListNameDialog: React.FunctionComponent<
  EditCandidateListNameDialog
> = ({ name, onToggle, onSubmit }) => {
  const [input, setInput] = useState(name)
  const [error, setError] = useState(false)
  const {
    title,
    body,
    errorCharLimit,
    label,
    placeholder,
    cancelButton,
    updateButton,
  } = usePickEpiContent().candidateList.changeListnameDialog

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    error && setError(false)
    setInput(event.target.value)
  }

  const handleSubmit = () => {
    if (!error) {
      setInput('')
      onSubmit(input)
    }
  }

  const handleBlur = () => {
    if (input.length < 3) {
      setError(true)
    }
  }

  return (
    <Dialog open onClose={onToggle}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          label={label}
          id="change-list-name-input"
          onChange={handleOnChange}
          placeholder={placeholder}
          onBlur={handleBlur}
          error={error}
          helperText={error ? errorCharLimit : null}
          value={input}
          fullWidth
          sx={{ mb: 2, mt: 1 }}
        />
        <Typography variant="body1">{body}</Typography>
      </DialogContent>
      <DialogContent>
        <DialogActions>
          <Button
            onClick={onToggle}
            variant="text"
            data-testid="change-name-dialog-cancel-button"
          >
            {cancelButton}
          </Button>
          <Button onClick={handleSubmit}>{updateButton}</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
