import React from 'react'
import { TableCandidateList } from '@local/CandidateList/components/TableCandidateList/TableCandidateList'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'
import { useGetExternalCandidateListQuery } from '@local/ExternalCandidateList/api/externalCandidateList.api'
import { candidateSuffix, replaceEpiVariables } from '@local/Utils/Helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { date } from '@trr/frontend-datetime'
import { useHistory, useLocation } from 'react-router-dom'
import CandidateListStatusChip from '@local/CandidateList/components/CandidateListStatusChip/CandidateListStatusChip'

export const ExternalCandidateList: React.FunctionComponent = () => {
  const history = useHistory()
  const { search } = useLocation()
  const listId = new URLSearchParams(search).get('listId')
  const { data, isLoading, error } = useGetExternalCandidateListQuery(listId)

  const { titleAndBody, errorContent, status } =
    usePickEpiContent().externalList
  const modifiedString = data?.modified
    ? replaceEpiVariables(titleAndBody.modified, {
        date: date.format(data.modified, { style: 'numeric' }),
      })
    : ''

  const goToProfile = (id: string) => {
    history.push({
      pathname: titleAndBody.profileLink,
      search: `?listId=${listId}&id=${id}`,
    })
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (error) {
    return (
      <EmptyResult
        heading={errorContent.errorTitle}
        description={errorContent.errorBody}
      />
    )
  }

  if (!data?.candidates?.length) {
    return (
      <EmptyResult
        heading={errorContent.emptyResultTitle}
        description={errorContent.emptyResultBody}
      />
    )
  }

  return (
    <Stack spacing={3} justifyContent="flex-start">
      <Typography variant="h1" gutterBottom sx={{ wordBreak: 'break-word' }}>
        {data.name}
      </Typography>
      <Typography variant="body1" mb={5}>
        {titleAndBody.body}
      </Typography>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography variant="h6">
          {candidateSuffix(data.candidates.length)}
        </Typography>
        <CandidateListStatusChip
          status={data.status}
          expiryDate={data.activation.expires}
          content={status}
          data-testid="candidate-list-status-chip"
        />
      </Stack>

      <TableCandidateList
        candidateList={data}
        onClick={goToProfile}
        metadata={{
          modified: modifiedString,
        }}
        readonly
      />
    </Stack>
  )
}
