import { Avatar, Button, Chip, styled } from '@mui/material'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { CustomPopper } from '@local/FindCandidate/Components/CustomPopper/CustomPopper'
import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material'

interface FilterOptionProps extends PropsWithChildren {
  label: string
  totalCount?: number
  selectCount?: number
  onResetFilters: () => void
}

const ChipLabelAndIcon = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: -7,
}))

export const FilterOptionGroup = ({
  label,
  totalCount,
  selectCount,
  onResetFilters,
  children,
}: FilterOptionProps) => {
  const chipRef = useRef(null)
  const [open, setOpen] = useState(false)
  const toggleFilter = () => {
    setOpen(!open)
  }

  const hasSelectedOptions = selectCount > 0

  const ResetButton = () => (
    <Button
      variant="text"
      onClick={onResetFilters}
      disabled={selectCount === 0}
      sx={{ alignSelf: 'flex-end' }}
    >
      Rensa
    </Button>
  )

  const SelectCountIndicator = (
    <Avatar sx={{ bgcolor: '#089188', color: '#fff !important' }}>
      {selectCount}
    </Avatar>
  )

  useEffect(() => {
    if (open) {
      const chipBottom = chipRef.current?.offsetTop
      const popperHeight = 700
      const viewportBottom = window.scrollY + window.innerHeight
      const shouldScrollIntoView = chipBottom + popperHeight > viewportBottom
      shouldScrollIntoView &&
        chipRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [open])

  return totalCount ? (
    <>
      <Chip
        label={
          <ChipLabelAndIcon>
            {label} {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </ChipLabelAndIcon>
        }
        onClick={toggleFilter}
        avatar={hasSelectedOptions ? SelectCountIndicator : undefined}
        sx={{ zIndex: open ? 2 : 'unset' }}
        aria-describedby="filter-item-chip"
        ref={chipRef}
        variant={open || hasSelectedOptions ? 'filled' : 'outlined'}
      />
      <CustomPopper
        label={label}
        headerIcon={<Close />}
        open={open}
        anchor={chipRef.current}
        footer={<ResetButton />}
        onClickHeaderIcon={toggleFilter}
        onClickAway={toggleFilter}
      >
        <>{children}</>
      </CustomPopper>
    </>
  ) : null
}
