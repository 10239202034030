import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { AddToCandidateListContent } from '@local/Types'

interface AddCandidateDialogProps {
  open: boolean
  content: AddToCandidateListContent
  handleClose: () => void
  handleCreate: (listName: string) => void
}

export const AddCandidateDialog = ({
  open,
  content,
  handleClose,
  handleCreate,
}: AddCandidateDialogProps) => {
  const [listNameInput, setListNameInput] = useState('')
  const [error, setError] = useState(false)

  const handleClickCreate = (event: React.FormEvent) => {
    event.preventDefault()
    const sanitizedString = listNameInput.trim()
    if (sanitizedString.length >= 3) {
      handleCreate(sanitizedString)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    setError(null)
  }, [listNameInput])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid="add-candidate-dialog"
    >
      <DialogTitle sx={{ padding: '1.5rem' }}>
        {content.dialogTitle}
      </DialogTitle>
      <form onSubmit={handleClickCreate}>
        <DialogContent sx={{ padding: '0 1.5rem' }}>
          <Typography variant="body1" sx={{ paddingBottom: '1rem' }}>
            {content.dialogSuggestionDescription}
          </Typography>
          <TextField
            label={content.dialogInputLabel}
            placeholder={content.dialogInputPlaceholder}
            error={error}
            helperText={content.dialogInputValidationText}
            fullWidth
            value={listNameInput}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            sx={{ marginBottom: '1rem' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setListNameInput(event.target.value)
            }
          />
          <Typography variant="body1">{content.dialogDescription}</Typography>
        </DialogContent>
        <DialogActions sx={{ padding: '0 1.5rem 2rem' }}>
          <Button variant="text" onClick={handleClose}>
            {content.dialogCancelButton}
          </Button>
          <Button type="submit" data-testid="submit-list-button">
            {content.dialogCreateButton}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
