import * as React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { isNil } from 'ramda'
import { Alert, AlertColor, AlertTitle } from '@mui/material'

import s from './Toaster.module.scss'

injectStyle()
export const Toaster = (): JSX.Element => (
  <ToastContainer
    hideProgressBar
    newestOnTop
    autoClose={1000}
    closeOnClick
    className={s.Toast}
  />
)

export interface PushFeedback {
  title?: string
  toastId?: number
  type?: AlertColor
  body?: string
  customAnimationTimerOffset?: number
  removeSuccessMessage?: (id: number) => void
}

export const pushFeedback = ({
  title,
  toastId,
  type = 'success',
  body = '',
  customAnimationTimerOffset,
  removeSuccessMessage,
}: PushFeedback): void => {
  // 60ms per character
  // at least give timeout 4 seconds if text is too short
  const titleLength = !isNil(title) ? title.length : 0
  const bodyLength = !isNil(body) ? body.length : 0
  let calculatedLength =
    titleLength + bodyLength * 60 + (customAnimationTimerOffset ?? 0)
  calculatedLength = calculatedLength < 4000 ? 4000 : calculatedLength

  const crypto = window.crypto
  const array = new Uint32Array(1)
  const id = crypto.getRandomValues(array)[0]

  const onCloseToast = () => {
    if (removeSuccessMessage) {
      removeSuccessMessage(toastId)
    }
  }

  toast(
    <Alert
      severity={type}
      sx={{ margin: 0 }}
      onClose={onCloseToast}
      data-testid="toaster-alert"
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {body}
    </Alert>,
    {
      toastId: id,
      bodyClassName: s.ToastBody,
      closeButton: false,
      autoClose: calculatedLength,
    }
  )
}
