import getConfig from '@local/Utils/ConfigService/getConfig'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'

export const { API_URL, PUBLIC_WEB_API_URL } = getConfig()

export const trrFetchBaseQuery = (useExternalUrl = false) =>
  fetchBaseQuery({
    baseUrl: useExternalUrl ? PUBLIC_WEB_API_URL : API_URL,
    prepareHeaders: (headers) => {
      const token = getIdToken()
      useExternalUrl || headers.set('authorization', `Bearer ${token}`)
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })
