import { downloadFile, previewFile } from '@local/Utils/Helpers'
import { API_URL, PUBLIC_WEB_API_URL } from '@local/Utils/Network'
import { getIdToken } from '@trr/app-shell-data'

export const downloadCvQuery = ({
  pdfId,
  clientId,
  clientName,
  candidateListId,
  isMedarbetare,
}: {
  pdfId: string
  clientId: string
  clientName: string
  candidateListId: string
  isMedarbetare: boolean
}) => {
  const token = getIdToken()
  if (isMedarbetare) {
    return fetch(`${API_URL}/Cv/clients/${clientId}/pdfs/${pdfId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': 'sv',
        method: 'GET',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Faulty CV, cannot download')
        }
        return res.blob()
      })
      .then((blob) => {
        downloadFile(blob, `${clientName} Cv`, 'pdf')
        return false
      })
      .catch(() => true)
  } else {
    return fetch(
      `${PUBLIC_WEB_API_URL}/candidatelists/${candidateListId}/profiles/${clientId}/cvpdf/${pdfId}`,
      {
        headers: {
          'Accept-Language': 'sv',
          method: 'GET',
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Faulty CV')
        }
        return res.blob()
      })
      .then((blob) => {
        downloadFile(blob, `${clientName} Cv`, 'pdf')
        return false
      })
      .catch(() => true)
  }
}

export const previewCvQuery = ({
  pdfId,
  clientId,
  candidateListId,
  isMedarbetare,
}: {
  pdfId: string
  clientId: string
  candidateListId: string
  isMedarbetare: boolean
}) => {
  const token = getIdToken()
  if (isMedarbetare) {
    return fetch(`${API_URL}/Cv/clients/${clientId}/pdfs/${pdfId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': 'sv',
        method: 'GET',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Faulty CV, cannot preview')
        }
        return res.blob()
      })
      .then((blob) => {
        previewFile(blob)
        return false
      })
      .catch(() => true)
  } else {
    return fetch(
      `${PUBLIC_WEB_API_URL}/candidatelists/${candidateListId}/profiles/${clientId}/cvpdf/${pdfId}`,
      {
        headers: {
          'Accept-Language': 'sv',
          method: 'GET',
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Faulty CV')
        }
        return res.blob()
      })
      .then((blob) => {
        previewFile(blob)
        return false
      })
      .catch(() => true)
  }
}
