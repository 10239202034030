import React, { PropsWithChildren } from 'react'
import { Box, Grid2 as Grid, Typography, styled } from '@mui/material'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

interface ProductPageProps {
  heading: string
  stickyElement?: React.ReactNode
}

const ProductPageTitle = styled(Typography)(({ theme }) => ({
  wordBreak: 'break-word',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.h3.fontSize,
    maxWidth: theme.breakpoints.values.md,
  },
}))

const StickyElement = styled(Box)(({ theme }) => ({
  wordBreak: 'break-word',
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: theme.spacing(4),
  },
}))

export const ProductPage: React.FC<PropsWithChildren<ProductPageProps>> = ({
  heading,
  children,
  stickyElement,
}) => {
  const { isDesktop } = useWindowSize()

  return (
    <Grid container spacing={2} direction={{ xs: 'column-reverse', md: 'row' }}>
      <Grid size={{ xs: 12, md: 8 }} sx={{ wordBreak: 'break-word' }}>
        {isDesktop && (
          <ProductPageTitle variant="h1" gutterBottom>
            {heading}
          </ProductPageTitle>
        )}
        {children}
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        {stickyElement && <StickyElement>{stickyElement}</StickyElement>}
      </Grid>
      {!isDesktop && (
        <Grid size={{ xs: 12 }}>
          <ProductPageTitle variant="h1" gutterBottom>
            {heading}
          </ProductPageTitle>
        </Grid>
      )}
    </Grid>
  )
}
