import React from 'react'
import { Link, Typography, styled } from '@mui/material'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

interface MultipleTextItemsProps {
  items: string[]
  icon?: React.ReactNode
  customCharLimit?: number
}

const JobtitleSeparator = styled('span')(() => ({
  margin: '0 .4rem',
  display: 'inline-block',
  alignSelf: 'center',
}))

const NonBreakingSpan = styled('span')(() => ({
  display: 'flex',
}))

const CenteredCell = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
}))

const LeftIconWrapper = styled('div')(() => ({
  marginRight: '.5rem',
  display: 'flex',
}))

export const MultipleTextItems = ({
  icon,
  items,
  customCharLimit,
}: MultipleTextItemsProps) => {
  const { isDesktop } = useWindowSize()
  const standardCharacterLimit = isDesktop ? 54 : 40
  const characterLimit = customCharLimit ?? standardCharacterLimit
  // Check for limit so item does not overflow on two lines. Arbitrary number from testing on desktop
  let itemShowLengthLimit = items?.reduce(
    (acc, item) => {
      if (acc.length + item.length <= characterLimit && !acc.skip) {
        return {
          length: acc.length + item.length + 2,
          limit: acc.limit + 1,
        }
      }
      return { ...acc, skip: true }
    },
    { length: 0, limit: 0, skip: false }
  ).limit
  itemShowLengthLimit = itemShowLengthLimit === 0 ? 1 : itemShowLengthLimit

  return (
    <CenteredCell>
      {icon && <LeftIconWrapper>{icon}</LeftIconWrapper>}
      {items?.slice(0, itemShowLengthLimit).map((item, index) => (
        <NonBreakingSpan key={index}>
          <Typography
            variant="body1"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: itemShowLengthLimit === 1 ? '50vw' : 'unset',
            }}
          >
            {item}
          </Typography>
          {index < items.length - 1 && <JobtitleSeparator>•</JobtitleSeparator>}
          {index === itemShowLengthLimit - 1 &&
            items.length > itemShowLengthLimit && (
              <Link component="span" variant="body1">
                +{(items.length - itemShowLengthLimit).toString()}
              </Link>
            )}
        </NonBreakingSpan>
      ))}
    </CenteredCell>
  )
}
