import React from 'react'
import { ProfileConsentSectionContent } from '@local/Types'
import { Stack, StackProps, Typography } from '@mui/material'
import { People } from '@mui/icons-material'

interface ProfileConsentSectionProps extends StackProps {
  sectionContent: ProfileConsentSectionContent
}

export const ProfileConsentSection: React.FC<ProfileConsentSectionProps> = ({
  sectionContent,
  ...props
}) => (
  <Stack spacing={1} direction={'row'} {...props}>
    <People sx={{ marginRight: '8px' }} />
    <Typography variant="body1">{sectionContent.body}</Typography>
  </Stack>
)
