import { SokKandidatContent } from '@local/Types'
import { useEpiContent } from '@trr/app-shell-data'

const usePickEpiContent = useEpiContent<SokKandidatContent>

export default usePickEpiContent

export const replaceEpiVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result?.replace(regex, variables[key])
  })
  return result
}
