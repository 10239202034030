import {
  AdditionalInformation,
  DeepLocation,
  JobTitle,
} from '@local/Types/MatchingApi.types'
import { CvPdf } from '@local/Profile/api/ProfileApi'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'

interface GetProfileParams {
  profileId: string
  listId: string
}

export interface ExternalProfileApiResponse {
  id: string
  cvPdf: CvPdf
  jobTitles: JobTitle[]
  personalInformation: ExternalPersonalInformation
  locationInformations: DeepLocation[]
  additionalInformation: AdditionalInformation
}

interface SocialMedia {
  type: string
  url: string
}

interface ExternalPersonalInformation {
  firstName: string
  lastName: string
  email: string
  mobilePhone: string
  socialMedia: SocialMedia[]
}

export const externalProfileApi = createApi({
  reducerPath: 'externalProfileApi',
  baseQuery: trrFetchBaseQuery(true),
  endpoints: (builder) => ({
    getExternalProfile: builder.query<
      ExternalProfileApiResponse,
      GetProfileParams
    >({
      query: ({ profileId, listId }) =>
        `/CandidateLists/${listId}/profiles/${profileId}`,
    }),
  }),
})

export const { useGetExternalProfileQuery } = externalProfileApi
