import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useCallback } from 'react'
import { CandidateList } from '@local/Types/MatchingApi.types'
import { TableCandidateListItem } from '@local/CandidateList/components/TableCandidateList/TableCandidateListItem'
import { useRemoveCandidateFromListMutation } from '@local/AdvisorList'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { date } from '@trr/frontend-datetime'
import { useSearchQueryParams } from '@local/FindCandidate/Hooks/useSearchQueryParams'

interface TableCandidateListProps {
  candidateList: CandidateList
  metadata: {
    modified: string
  }
  readonly?: boolean
  onClick: (id: string) => void
}

export const TableCandidateList = ({
  candidateList,
  metadata,
  readonly,
  onClick,
}: TableCandidateListProps) => {
  const [removeCandidate] = useRemoveCandidateFromListMutation()

  const { formattedSearchParams, setSearchParamField } = useSearchQueryParams()
  const rowsPerPageOptions = [20, 50, 100]
  const showPagination = candidateList.totalHits > rowsPerPageOptions[0]

  const handleRemoveCandidate = (candidateId: string, listId: string) => {
    void removeCandidate({ candidateId, listId })
  }

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setSearchParamField((newPage + 1).toString(), 'page')
    },
    [setSearchParamField]
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchParamField(event.target.value, 'pageSize')
    },
    [setSearchParamField]
  )

  return (
    <TableContainer>
      <Table size="medium">
        <TableBody>
          {candidateList?.candidates?.map((candidate, i) => (
            <TableCandidateListItem
              candidate={candidate}
              key={`${candidate.id}_${i}`}
              onClick={onClick}
              onDelete={
                !readonly
                  ? () => handleRemoveCandidate(candidate.id, candidateList.id)
                  : undefined
              }
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              sx={{ borderBottom: 'none' }}
              colSpan={showPagination ? 1 : 2}
            >
              <Typography variant="body1">
                {replaceEpiVariables(metadata?.modified, {
                  date: date.format(candidateList.modified, {
                    style: 'numeric',
                  }),
                })}
              </Typography>
            </TableCell>
            {showPagination && (
              <TablePagination
                sx={{ borderBottom: 'none' }}
                rowsPerPageOptions={rowsPerPageOptions}
                colSpan={2}
                count={candidateList.totalHits}
                rowsPerPage={formattedSearchParams.limit}
                page={formattedSearchParams.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data-testid="pagination"
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
