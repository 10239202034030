import React, { useState } from 'react'
import { useUpdateCandidateListNameMutation } from '@local/AdvisorList'
import { EditCandidateListNameDialog } from '@local/CandidateList/components/EditCandidateListName/EditCandidateListNameDialog'
import { IconButton, Tooltip } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { CandidateList } from '@local/Types/MatchingApi.types'

interface EditCandidateListName {
  list: Pick<CandidateList, 'name' | 'id'>
}

export const EditCandidateListName: React.FunctionComponent<
  EditCandidateListName
> = ({ list: { name, id } }) => {
  const [isActive, setIsActive] = useState(false)
  const [changeName] = useUpdateCandidateListNameMutation()

  const { changeListNameTooltip } = usePickEpiContent().candidateList.actions

  const toggleDialog = () => {
    setIsActive((prevState) => !prevState)
  }

  const onSubmit = (newName: string) => {
    void changeName({ id, name: newName })
    setIsActive(false)
  }

  return (
    <>
      <Tooltip title={changeListNameTooltip}>
        <IconButton onClick={toggleDialog} data-testid="change-name-button">
          <Edit />
        </IconButton>
      </Tooltip>
      {isActive && (
        <EditCandidateListNameDialog
          name={name}
          onToggle={toggleDialog}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}
