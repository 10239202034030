interface Configuration {
  API_URL: string
  PUBLIC_WEB_API_URL: string
  MEDIA_URL: string
  AUTHORITY_URL: string
  ADVISOR_SESSION_KEY: string
  YOUTUBE_API_KEY: string
}

// This configuration will be used for local development
const compiletimeConfiguration = {
  API_URL: process.env.API_URL,
  PUBLIC_WEB_API_URL: process.env.PUBLIC_WEB_API_URL,
  MEDIA_URL: process.env.MEDIA_URL,
  AUTHORITY_URL: process.env.AUTHORITY_URL,
  ADVISOR_SESSION_KEY: process.env.ADVISOR_SESSION_KEY,
  YOUTUBE_API_KEY: process.env.YOUTUBE_API_KEY,
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
  REPLACEABLE: 'CONFIG',
}

const getConfig = (): Configuration => ({
  ...compiletimeConfiguration,
  ...APP_CONFIGURATION,
})

export default getConfig
