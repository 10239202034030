import { ProductPage } from '@local/Components/ProductPage/ProductPage'
import { useGetExternalProfileQuery } from '@local/ExternalProfile/api/externalProfile.api'
import { ProfileCallToAction } from '@local/Profile/components/ProfileCallToAction'
import { ProfileJobtitleSection } from '@local/Profile/components/ProfileJobtitleSection'
import { ProfileLocationSection } from '@local/Profile/components/ProfileLocationSection'
import { ProfileWorkTypeSection } from '@local/Profile/components/ProfileWorkTypeSection'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { CircularProgress, Divider, Stack } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

export const ExternalProfile: React.FunctionComponent = () => {
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const listId = urlParams.get('listId')
  const profileId = urlParams.get('id')

  const { data, isLoading } = useGetExternalProfileQuery({ listId, profileId })

  const { jobtitleSection, locationSection, workTypeSection, callToAction } =
    usePickEpiContent().externalProfile

  const hasAdditionalInformation =
    data?.additionalInformation?.formsOfEmployment?.length > 0 ||
    data?.additionalInformation?.extentsOfEmployment?.length > 0

  const userName = `${data?.personalInformation?.firstName} ${data?.personalInformation?.lastName}`
  const linkedInUrl =
    data?.personalInformation?.socialMedia?.find((x) => x.type === 'linkedin')
      ?.url ?? ''

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <ProductPage
      heading={userName}
      stickyElement={
        <ProfileCallToAction
          email={data.personalInformation.email}
          mobilePhone={data.personalInformation.mobilePhone}
          cvPdf={data.cvPdf}
          linkedInUrl={linkedInUrl}
          userId={data.id}
          userName={userName}
          listId={listId}
          content={callToAction}
        />
      }
    >
      <Stack spacing={3} divider={<Divider />}>
        <ProfileJobtitleSection
          jobTitles={data?.jobTitles ?? []}
          content={jobtitleSection}
        />

        <ProfileLocationSection
          content={locationSection}
          otherPreferences={data?.additionalInformation?.otherPreferences}
          locations={data.locationInformations}
        />

        {hasAdditionalInformation && (
          <ProfileWorkTypeSection
            content={workTypeSection}
            formsOfEmployment={data?.additionalInformation?.formsOfEmployment}
            extentsOfEmployment={
              data?.additionalInformation?.extentsOfEmployment
            }
          />
        )}
      </Stack>
    </ProductPage>
  )
}
