import React from 'react'
import { Chip, ChipProps } from '@mui/material'
import { date } from '@trr/frontend-datetime'
import { replaceEpiVariables } from '@local/Utils/Hooks/usePickEpiContent'
import { CandidateListStatus } from '@local/AdvisorList/types'
import { isWithinSevenDays } from '@local/Utils/Helpers'

interface CandidateListStatusChip
  extends Omit<ChipProps, 'label' | 'variant' | 'content'> {
  expiryDate?: string
  status: CandidateListStatus
  content: {
    activeText: string
    activeExpiringSoonText: string
    inactiveText: string
  }
}

const CandidateListStatusChip: React.FC<CandidateListStatusChip> = ({
  expiryDate,
  status,
  content: { activeExpiringSoonText, activeText, inactiveText },
  ...otherProps
}) => {
  const isExpiringWithinSevenDays = isWithinSevenDays(expiryDate)

  const chipProps: ChipProps = (() => {
    if (status === CandidateListStatus.Active) {
      return {
        label: replaceEpiVariables(
          isExpiringWithinSevenDays ? activeExpiringSoonText : activeText,
          {
            externalExpiration: expiryDate && date.format(expiryDate),
          }
        ),
        variant: isExpiringWithinSevenDays
          ? 'status-warning'
          : 'status-success',
        'data-testid': 'active-chip',
      }
    }

    return {
      label: inactiveText,
      variant: 'status-info',
      'data-testid': 'inactive-chip',
    }
  })()

  return <Chip {...chipProps} {...otherProps} />
}

export default CandidateListStatusChip
