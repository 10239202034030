import { useIsFeatureEnabled } from '@trr/app-shell-data'

export const useFeatureFlag = () => {
  const testFlagIsEnabled = useIsFeatureEnabled('testFlag')

  const isShareLinkUtmTagEnabled = useIsFeatureEnabled(
    'Sokkandidat-Frontend_shareLinkUtmTagEnabled_perm_240821'
  )

  return {
    isShareLinkUtmTagEnabled,
    testFlagIsEnabled,
  }
}
