// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prpj7WT2G98sY97GG9no{padding:0 !important}.Toastify__toast-container{z-index:39 !important;top:0 !important;width:unset !important;top:6em !important}.Toastify__toast-container>div{padding:0 !important;min-height:unset !important}`, "",{"version":3,"sources":["webpack://./src/Components/Toaster/Toaster.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CAIA,2BACE,qBAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,+BACE,oBAAA,CACA,2BAAA","sourcesContent":[".ToastBody {\n  padding: 0 !important;\n}\n\n:global {\n  .Toastify__toast-container {\n    z-index: 39 !important;\n    top: 0 !important;\n    width: unset !important;\n    top: 6em !important;\n\n    & > div {\n      padding: 0 !important;\n      min-height: unset !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToastBody": `prpj7WT2G98sY97GG9no`
};
export default ___CSS_LOADER_EXPORT___;
