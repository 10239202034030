import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { Alert } from '@mui/material'

const InactiveCandidatesInfo: React.FunctionComponent = () => {
  const { inactiveCandidatesInfo } = usePickEpiContent().candidateList.actions

  return <Alert severity="info">{inactiveCandidatesInfo}</Alert>
}

export default InactiveCandidatesInfo
