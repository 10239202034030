import { CandidateListStatus } from '@local/AdvisorList/types'
import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { replaceEpiVariables } from '@local/Utils/Hooks/usePickEpiContent'
import { RemoveCandidateListDialogContent } from '@local/Types'

interface RemoveListDialogProps {
  content: RemoveCandidateListDialogContent
  listInfo: {
    name: string
    numCandidates: number
    status: CandidateListStatus
  }
  onToggle: () => void
  onRemoveList: () => void
}

const RemoveListDialog: React.FunctionComponent<RemoveListDialogProps> = ({
  listInfo,
  content,
  onToggle,
  onRemoveList,
}) => {
  const {
    title,
    titleActive,
    body,
    bodyNoCandidates,
    bodyActiveBlock1,
    bodyActiveBlock2,
    actionButton,
    cancelButton,
  } = content

  return (
    <Dialog open onClose={onToggle}>
      <DialogTitle>
        {listInfo.status === CandidateListStatus.Active ? titleActive : title}
      </DialogTitle>
      <DialogContent>
        {listInfo.status === CandidateListStatus.Active ? (
          <>
            <Typography variant="body1" gutterBottom>
              {replaceEpiVariables(bodyActiveBlock1, {
                listName: listInfo.name,
              })}
            </Typography>
            <Typography variant="body1">{bodyActiveBlock2}</Typography>
          </>
        ) : (
          <Typography variant="body1">
            {replaceEpiVariables(
              listInfo.numCandidates > 0 ? body : bodyNoCandidates,
              { listName: listInfo.name }
            )}
          </Typography>
        )}
      </DialogContent>
      <DialogContent>
        <DialogActions>
          <Button onClick={onToggle} variant="text">
            {cancelButton}
          </Button>
          <Button
            onClick={onRemoveList}
            data-testid="remove-list-dialog-confirm-button"
          >
            {actionButton}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default RemoveListDialog
