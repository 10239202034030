import React, { useState } from 'react'
import { Link as LinkIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useActivateExternalMutation } from '@local/AdvisorList'
import { CandidateList } from '@local/Types/MatchingApi.types'
import { useFeatureFlag, usePickEpiContent } from '@local/Utils/Hooks'
import { pushFeedback } from '@local/Components/Toaster/Toaster'
import ExternalLinkDialog from '@local/CandidateList/components/ExternalLink/ExternalLinkDialog'
import {
  generateUtmParams,
  generateUtmDate,
} from '@local/Utils/Helpers/urchinTrackingModuleHelper'

interface ExternalLinkButtonProps {
  variant: 'copy' | 'activate'
  list: Pick<CandidateList, 'name' | 'id'>
}

const ExternalLinkButton = ({ variant, list }: ExternalLinkButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [activateExternal] = useActivateExternalMutation()
  const {
    copiedText,
    createTooltip,
    copyTooltip,
    link,
    utmSource,
    utmCampaign,
    utmContent,
    utmMedium,
    utmTerm,
  } = usePickEpiContent().candidateList.externalLink

  const handleToggleDialogOpen = () => {
    setIsDialogOpen((prevState) => !prevState)
  }

  const { isShareLinkUtmTagEnabled } = useFeatureFlag()

  const handleToggleLink = () => {
    void activateExternal({ listId: list.id, activate: true })
    handleCopyLink()
    setIsDialogOpen(false)
  }

  const handleCopyLink = () => {
    const utmTagParams = isShareLinkUtmTagEnabled
      ? `&${generateUtmParams({
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          utm_content: `${generateUtmDate(new Date())}${utmContent}`,
          utm_term: utmTerm,
        })}`
      : ''
    void navigator.clipboard.writeText(
      `${location.host}${link}?listId=${list.id}${utmTagParams}`
    )
    pushFeedback({
      body: copiedText,
    })
  }

  return (
    <>
      <Tooltip title={variant === 'activate' ? createTooltip : copyTooltip}>
        <IconButton
          onClick={
            variant === 'activate' ? handleToggleDialogOpen : handleCopyLink
          }
          data-testid="external-link-button"
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
      {isDialogOpen && (
        <ExternalLinkDialog
          type="activate"
          listName={list.name}
          onCancel={handleToggleDialogOpen}
          onConfirm={handleToggleLink}
        />
      )}
    </>
  )
}

export default ExternalLinkButton
