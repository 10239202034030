import {
  JobFiltersRequest,
  NewMatchSearchApiRequest,
} from '@local/Types/MatchingApi.types'

export const getActiveFilterCount = (
  formattedSearchParams: NewMatchSearchApiRequest
): number =>
  Object.keys(formattedSearchParams?.filters).reduce(
    (acc, key) =>
      acc +
      formattedSearchParams?.filters[key as keyof JobFiltersRequest]?.length,
    0
  )
