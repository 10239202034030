export const isWithinSevenDays = (date: Date | string): boolean => {
  if (!date) {
    return false
  }
  const today = new Date()
  const futureDate = date instanceof Date ? date : new Date(Date.parse(date))
  if (today.getTime() > futureDate.getTime()) {
    return false
  }
  today.setDate(today.getDate() + 7)
  return today.getTime() > futureDate.getTime()
}
