import React, { ChangeEvent, useState } from 'react'
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { uniq } from 'ramda'

interface JobtitleInputProps {
  values: string[]
  onInputChange: (
    event: ChangeEvent<HTMLInputElement>,
    jobTitle: string[]
  ) => void
}

export const JobtitleInput = ({
  values,
  onInputChange,
}: JobtitleInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const { label, placeholder } =
    usePickEpiContent().sokKandidatNy.yrkesrollSearch

  const isValid = (inputValue: string) => inputValue.trim().length > 1
  const capitalize = (text: string) =>
    text.charAt(0).toUpperCase() + text.slice(1)

  const handleJobtitleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValues: string[]
  ) => {
    const formattedNewValues = uniq(
      newValues.map((value) => capitalize(value))
    ).filter((x) => isValid(x))
    onInputChange(null, uniq(formattedNewValues))
  }

  const handleJobtitleBlur = () => {
    if (isValid(inputValue)) {
      const formattedInputValue = capitalize(inputValue)
      onInputChange(null, [...values, formattedInputValue])
    }
    setInputValue('')
  }

  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        freeSolo
        multiple
        options={[]}
        value={values}
        inputValue={inputValue}
        onBlur={handleJobtitleBlur}
        onChange={handleJobtitleChange}
        onInputChange={(_event, value) => setInputValue(value)}
        popupIcon={null}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        data-testid="professions-autocomplete"
      />
    </Box>
  )
}
