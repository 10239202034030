import { Candidate } from '@local/Types/MatchingApi.types'

export interface CandidateListsApiModel {
  id: string
  name: string
  status: CandidateListStatus
  numberOfCandidates: number
  externalExpiration: string
}

export enum CandidateListStatus {
  OnGoing,
  Active,
  Expired,
  Deactivated,
}

export interface ApiUpdateCandidateListNameModel {
  id: string
  name: string
}

export interface ApiCreateCandidateListModel {
  name: string
  candidates: Omit<Candidate, 'isValid' | 'isPublished'>[]
}

export interface ApiAddCandidateModel {
  listId: string
  listName: string
  candidate: Omit<Candidate, 'isValid' | 'isPublished'>
}

export interface ApiAddCandidatesModel {
  listId: string
  listName: string
  candidates: Omit<Candidate, 'isValid' | 'isPublished'>[]
}

export interface ApiRemoveCandidateModel {
  listId: string
  candidateId: string
}

export interface ApiActivateCandidateListModel {
  listId: string
  activate: boolean
}

export interface ApiCreateEmailDraft {
  listId: string
  subject: string
}

export interface CandidateListActivation {
  created: string
  expires: string
}
