import { Option } from '@local/FindCandidate/Components/FilterBar/FilterOption'
import { Facet, Match, SelectedCandidate } from '@local/Types/MatchingApi.types'
import { SearchFilteringFacetsContent } from '@local/Types'

export const mapMatchToCandidate = (
  matchedClient: Match
): SelectedCandidate => ({
  id: matchedClient.id,
  jobTitles: matchedClient.jobTitles,
  name: `${matchedClient.personalInformation.firstName} ${matchedClient.personalInformation.lastName}`,
})

export const mapFacetsToOptions = (
  facets: Facet[],
  searchFilteringFacets: SearchFilteringFacetsContent
): Option[] =>
  facets?.map((facet) => ({
    key: facet.name,
    title:
      searchFilteringFacets?.[
        facet.name as keyof SearchFilteringFacetsContent
      ] ?? facet.name,
    count: facet.count,
  }))

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}
