import React from 'react'
import { BoxProps, Box, Typography, Stack, styled, Button } from '@mui/material'

import EmptyIllustration from './EmptyIllustration'

type EmptyResultAction = {
  label: string
} & (
  | {
      onClick: () => void
    }
  | {
      link: string
    }
)

interface EmptyResultProps extends BoxProps {
  heading: string
  description: string
  action?: EmptyResultAction
  secondaryAction?: EmptyResultAction
}

const IllustrationWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '386px',
  padding: theme.spacing(0, 5),
}))

const EmptyResult: React.FC<EmptyResultProps> = ({
  heading,
  description,
  action,
  secondaryAction,
  ...boxProps
}) => (
  <Box {...boxProps}>
    <Stack
      spacing={3}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <IllustrationWrapper>
        <EmptyIllustration />
      </IllustrationWrapper>
      <Typography variant="h3" gutterBottom>
        {heading}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      <Stack direction={'row'} spacing={2}>
        {secondaryAction && (
          <Button
            variant="outlined"
            color="primary"
            onClick={
              'onClick' in secondaryAction ? secondaryAction.onClick : undefined
            }
            href={'link' in secondaryAction ? secondaryAction.link : undefined}
            data-testid={'empty-result-secondary-button'}
          >
            {secondaryAction.label}
          </Button>
        )}
        {action && (
          <Button
            variant="contained"
            color="primary"
            onClick={'onClick' in action ? action.onClick : undefined}
            href={'link' in action ? action.link : undefined}
            date-testid={'empty-result-primary-button'}
          >
            {action.label}
          </Button>
        )}
      </Stack>
    </Stack>
  </Box>
)

export default EmptyResult
