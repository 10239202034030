import { CandidateListsApiModel } from '@local/AdvisorList/types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { appRoutes, candidateSuffix } from '@local/Utils/Helpers'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ListItemText, Stack, TableCell } from '@mui/material'
import DeletableTableRow from '@local/Components/DeletableTableRow/DeletableTableRow'
import { useDeleteCandidateListMutation } from '@local/AdvisorList/api/CandidateListApi'
import RemoveListDialog from '@local/CandidateList/components/RemoveListDialog/RemoveListDialog'
import CandidateListStatusChip from '@local/CandidateList/components/CandidateListStatusChip/CandidateListStatusChip'
import { useWindowSize } from '@local/Utils/Hooks/useWindowSize'

interface CandidateListsItem {
  list: CandidateListsApiModel
}

const AdvisorListItem: React.FunctionComponent<CandidateListsItem> = ({
  list,
}) => {
  const [isRemoveListDialogOpen, setIsRemoveListDialogOpen] = useState(false)
  const history = useHistory()
  const { listOfCandidateLists, removeCandidateListDialog } =
    usePickEpiContent().minaListor

  const [deleteList] = useDeleteCandidateListMutation()

  const { isDesktop } = useWindowSize()

  const handleOnClick = () => {
    const urlPrefix = history.location.pathname.split('medarbetare')[0]
    const baseUrl = 'medarbetare/matcha-kandidater/'
    history.push(
      `${urlPrefix}${baseUrl}${appRoutes.kandidatLista}?id=${list.id}`
    )
  }

  const handleConfirmDeleteList = () => {
    void deleteList(list.id)
  }

  const handleToggleRemoveDialogOpen = () => {
    setIsRemoveListDialogOpen((prev) => !prev)
  }
  return (
    <>
      <DeletableTableRow
        data-testid="candidate-lists-item"
        hover
        onClick={handleOnClick}
        onDelete={handleToggleRemoveDialogOpen}
        sx={{ cursor: 'pointer' }}
      >
        {isDesktop ? (
          <>
            <TableCell sx={{ minWidth: 200, wordBreak: 'break-word' }}>
              <ListItemText
                primary={list.name}
                secondary={candidateSuffix(list.numberOfCandidates)}
              />
            </TableCell>
            <TableCell sx={{ width: 200 }}>
              <CandidateListStatusChip
                status={list.status}
                expiryDate={list.externalExpiration}
                content={listOfCandidateLists}
              />
            </TableCell>
          </>
        ) : (
          <TableCell sx={{ wordBreak: 'break-word', py: 1, maxWidth: 200 }}>
            <ListItemText
              primary={list.name}
              secondary={
                <Stack spacing={1}>
                  <span>{candidateSuffix(list.numberOfCandidates)}</span>
                  <CandidateListStatusChip
                    status={list.status}
                    expiryDate={list.externalExpiration}
                    content={listOfCandidateLists}
                    size="small"
                    sx={{
                      color: 'text.secondary',
                      fontSize: (t) => t.typography.body2.fontSize,
                      fontWeight: (t) => t.typography.fontWeightRegular,
                    }}
                  />
                </Stack>
              }
              primaryTypographyProps={{
                sx: {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            />
          </TableCell>
        )}
      </DeletableTableRow>
      {isRemoveListDialogOpen && (
        <RemoveListDialog
          content={removeCandidateListDialog}
          listInfo={{
            name: list.name,
            numCandidates: list.numberOfCandidates,
            status: list.status,
          }}
          onToggle={handleToggleRemoveDialogOpen}
          onRemoveList={handleConfirmDeleteList}
        />
      )}
    </>
  )
}

export default AdvisorListItem
