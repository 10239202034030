import {
  CandidateListsApiModel,
  CandidateListStatus,
} from '@local/AdvisorList/types'
import { Candidate, CandidateList } from '@local/Types/MatchingApi.types'

export const testCandidate: Candidate = {
  id: '1111',
  name: 'Test Testsson',
  isValid: true,
  isPublished: true,
  jobTitles: [
    {
      name: 'Jobb1',
      id: '1222',
      experience: 2,
    },
    { name: 'Jobb2', id: '1223', experience: 3 },
  ],
}

export const testCandidate2: Candidate = {
  id: '1112',
  name: 'Trr Trrsson',
  isValid: true,
  isPublished: true,
  jobTitles: [
    {
      name: 'Jobb1',
      id: '1222',
      experience: 2,
    },
    { name: 'Jobb2', id: '1223', experience: 3 },
  ],
}

export const testCandidateInactive: Candidate = {
  id: '457',
  name: 'Inaktivsson hej',
  isPublished: false,
  isValid: false,
  jobTitles: [
    {
      name: 'Jobb1',
      id: '1222',
      experience: 2,
    },
    { name: 'Jobb2', id: '1223', experience: 3 },
  ],
}

export const testCandidateApiModelList1: CandidateListsApiModel = {
  id: '11',
  name: 'test list',
  status: CandidateListStatus.OnGoing,
  numberOfCandidates: 2,
  externalExpiration: null,
}

export const testCandidateApiModelList2: CandidateListsApiModel = {
  id: '12',
  name: 'test list',
  status: CandidateListStatus.OnGoing,
  numberOfCandidates: 2,
  externalExpiration: null,
}

export const testCandidateList: CandidateList = {
  id: '1',
  name: 'test list',
  status: CandidateListStatus.OnGoing,
  activation: {
    created: '2022-01-01',
    expires: '2022-02-01',
  },
  candidates: [testCandidate, testCandidate2],
  modified: '2022-01-03',
  mostRecentEmailDraftCreated: '2022-01-02',
  totalValidCandidates: 2,
  totalHits: 2,
  totalPages: 1,
}
