import { CandidateListsApiModel } from '@local/AdvisorList/types'
import { AddCandidateDialog } from '@local/FindCandidate/Components/AddCandidateDialog/AddCandidateDialog'
import { CustomPopper } from '@local/FindCandidate/Components/CustomPopper/CustomPopper'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ArrowDropUp, ArrowDropDown, Close } from '@mui/icons-material'
import {
  Chip,
  styled,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
} from '@mui/material'
import React, { useRef, useState } from 'react'

const ChipLabelAndIcon = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: -7,
}))

interface ProfileCandidateListProps {
  lists: CandidateListsApiModel[]
  onCreateList: (name: string) => Promise<unknown>
  onAddToList: (name: string, id: string) => Promise<unknown>
}

export const ProfileCandidateList: React.FC<ProfileCandidateListProps> = ({
  lists,
  onCreateList,
  onAddToList,
}) => {
  const chipRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [createListOpen, setCreateListOpen] = useState(false)

  const { addToCandidateList } = usePickEpiContent().profil

  const toggleFilter = () => {
    setOpen(!open)
  }

  const handleAddToList = (name: string, id: string) => {
    void onAddToList(name, id).then(() => {
      setOpen(false)
    })
  }

  const handleCreateList = (name: string) => {
    void onCreateList(name).then(() => {
      setCreateListOpen(false)
      setOpen(false)
    })
  }

  return (
    <Box>
      <Chip
        label={
          <ChipLabelAndIcon>
            {addToCandidateList.addToList}
            {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </ChipLabelAndIcon>
        }
        onClick={toggleFilter}
        sx={{ zIndex: 2, marginBottom: '16px' }}
        aria-describedby="filter-item-chip"
        data-testid="add-to-list-chip"
        ref={chipRef}
        variant={'outlined'}
      />
      <CustomPopper
        label={addToCandidateList.addToList}
        headerIcon={<Close />}
        open={open}
        anchor={chipRef.current}
        onClickHeaderIcon={toggleFilter}
        onClickAway={toggleFilter}
      >
        <List sx={{ padding: 0 }} disablePadding>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton
              role="button"
              sx={{ padding: '8px 16px' }}
              onClick={() => setCreateListOpen(true)}
              data-testid="create-list-button"
              dense
            >
              <ListItemText primary={addToCandidateList.addToNewList} />
            </ListItemButton>
          </ListItem>
          {lists?.map((list) => (
            <ListItem key={list.id} sx={{ padding: 0 }}>
              <ListItemButton
                role="button"
                sx={{ padding: '8px 16px' }}
                onClick={() => handleAddToList(list.name, list.id)}
                dense
              >
                <ListItemText primary={list.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CustomPopper>

      <AddCandidateDialog
        open={createListOpen}
        content={addToCandidateList}
        handleClose={() => setCreateListOpen(false)}
        handleCreate={handleCreateList}
      />
    </Box>
  )
}
