import { JobTitle } from '@local/Types/MatchingApi.types'
import { ProfileJobtitleSectionContent } from '@local/Types'
import { Chip, Stack, Typography } from '@mui/material'
import React from 'react'

interface ProfileJobtitleSectionProps {
  content: ProfileJobtitleSectionContent
  jobTitles: JobTitle[]
}

export const ProfileJobtitleSection: React.FC<ProfileJobtitleSectionProps> = ({
  jobTitles,
  content,
}) => (
  <Stack spacing={2}>
    <Typography variant="h6">{content.title}</Typography>
    <Stack direction="row" spacing={1} flexWrap={'wrap'} useFlexGap>
      {jobTitles.map((jobTitle) => (
        <Chip key={`${jobTitle.id + jobTitle.name}`} label={jobTitle.name} />
      ))}
    </Stack>
  </Stack>
)
