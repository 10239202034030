import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import store from '@local/Store/configureStore'
import { CandidateList } from '@local/CandidateList'
import { Toaster } from '@local/Components/Toaster/Toaster'
import { AdvisorLists } from '@local/AdvisorList'
import { appRoutes } from '@local/Utils/Helpers'
import { FindCandidate } from '@local/FindCandidate'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { QueryParamProvider } from 'use-query-params'
import { GTMTracker } from '@trr/gtm-tracking'
import { CustomReactRouterAdapter } from '@local/Utils/Helpers/CustomReactRouterAdapter'
import { Profile } from '@local/Profile/Profile'
import { ExternalCandidateList } from '@local/ExternalCandidateList/ExternalCandidateList'
import { ExternalProfile } from '@local/ExternalProfile/ExternalProfile'
import { mergeContentWithFallback } from '@local/Utils/Helpers/mergeContentWithFallback'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { AppShellRouter, KeyRoute } from '@local/Utils/Router'

interface AppShellConfiguration {
  currentKey: string
  appShellData: AppShellDataProps
}

const App: React.FunctionComponent<AppShellConfiguration> = ({
  currentKey,
  appShellData,
}) => {
  const theme = createTheme(themeOptions, defaultLocale)

  useEffect(() => {
    if (
      appShellData.currentKey !== appRoutes.kandidatLista &&
      appShellData.currentKey !== appRoutes.profil &&
      appShellData.currentKey !== appRoutes.minaListor
    ) {
      sessionStorage.removeItem('sokKandidat-savedParams')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GTMTracker mfName="sokkandidat">
        <Provider store={store}>
          <AppShellDataProvider
            value={{
              ...appShellData,
              content: mergeContentWithFallback(appShellData.content),
            }}
          >
            <Toaster />
            <QueryParamProvider
              adapter={CustomReactRouterAdapter}
              options={{ updateType: 'replaceIn' }}
            >
              <AppShellRouter currentKey={currentKey}>
                <KeyRoute urlKey={appRoutes.sok}>
                  <FindCandidate />
                </KeyRoute>
                <KeyRoute urlKey={appRoutes.sokNy}>
                  <FindCandidate />
                </KeyRoute>
                <KeyRoute urlKey={appRoutes.minaListor}>
                  <AdvisorLists />
                </KeyRoute>
                <KeyRoute urlKey={appRoutes.kandidatLista}>
                  <CandidateList />
                </KeyRoute>
                <KeyRoute urlKey={appRoutes.profil}>
                  <Profile />
                </KeyRoute>
                <KeyRoute urlKey={appRoutes.externProfil}>
                  <ExternalProfile />
                </KeyRoute>
                <KeyRoute urlKey={appRoutes.externLista}>
                  <ExternalCandidateList />
                </KeyRoute>
              </AppShellRouter>
            </QueryParamProvider>
          </AppShellDataProvider>
        </Provider>
      </GTMTracker>
    </ThemeProvider>
  )
}

export default App
