import { DeepLocation, JobTitle } from '@local/Types/MatchingApi.types'
import { convertToSwedishLocationTypes } from '@local/Utils/Helpers'

export const candidateSuffix = (numCandidates: number): string =>
  `${numCandidates} ${
    numCandidates !== 1 ? 'aktiva kandidater' : 'aktiv kandidat'
  }`

export const jobTitlesToUrlStrings = (jobTitles: JobTitle[]) =>
  jobTitles.map((title) => `${title.name}|${title.id}`)

export const replaceEpiVariables = (
  text: string,
  variables: { [key: string]: string } = {}
): string => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result?.replace(regex, variables[key])
  })
  return result
}

export const deepLocationToSwedish = (location: DeepLocation): string => {
  const swedishType = convertToSwedishLocationTypes(location.type)

  return `${location.name}, ${swedishType}`
}

export const appRoutes = {
  sok: 'sok',
  sokNy: 'sok-ny',
  minaListor: 'mina-listor',
  kandidatLista: 'kandidatlista',
  profil: 'profil',
  externProfil: 'extern-profil',
  externLista: 'extern-lista',
}

export const createHrefLinkedIn = (url: string): string => {
  const startIndex = url.indexOf('linke')

  return '//' + url.substring(startIndex)
}
