import { UserRoles } from '@local/App.types'
import useGetRole from '@local/Utils/Hooks/useGetRole/useGetRole'
import { useLayoutEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export const useSetBackButton = (url: string, reqParam = 'true') => {
  const elRef = useRef(null)
  const history = useHistory()
  const { roles } = useGetRole()

  const isOnlyHandlaggare =
    roles?.includes(UserRoles.HandlaggareStudier) &&
    !roles?.includes(UserRoles.MatchaKandidater)

  useLayoutEffect(() => {
    const handleBackToList = (e: MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      history.push(url)
    }

    if (isOnlyHandlaggare) {
      const bredcrumb: HTMLElement = document.querySelector(
        'nav[data-testid=breadcrumbs]'
      )
      bredcrumb.style.display = 'none'
    } else if (reqParam) {
      elRef.current = document.querySelector('nav[data-testid=breadcrumbs] a')

      elRef.current = elRef.current
        ? elRef.current
        : document.querySelector('nav[data-testid=breadcrumbs] button')

      elRef.current?.addEventListener('click', handleBackToList)
      elRef.current?.href && elRef.current.setAttribute('href', url)
    }

    return () => {
      elRef.current?.removeEventListener('click', handleBackToList)
    }
  }, [url, reqParam, history, isOnlyHandlaggare])
}
