import { CandidateList } from '@local/Types/MatchingApi.types'
import { trrFetchBaseQuery } from '@local/Utils/Network'
import { createApi } from '@reduxjs/toolkit/query/react/'

export const externalCandidateListApi = createApi({
  reducerPath: 'externalCandidateListApi',
  baseQuery: trrFetchBaseQuery(true),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getExternalCandidateList: builder.query<CandidateList, string>({
      query: (id) => `/CandidateLists/${id}`,
    }),
  }),
})

export const { useGetExternalCandidateListQuery } = externalCandidateListApi
