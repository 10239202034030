import React from 'react'
import { Stack, Typography, Paper } from '@mui/material'

interface CallToActionProps {
  title?: string
}

const CallToAction: React.FC<React.PropsWithChildren<CallToActionProps>> = ({
  title,
  children,
}) => (
  <Paper variant="outlined" sx={{ py: 2, px: 3 }}>
    <Stack spacing={1} alignItems="flex-start">
      <Typography variant="h6">{title}</Typography>
      {children}
    </Stack>
  </Paper>
)

export default CallToAction
