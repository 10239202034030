import { API_URL } from '@local/Utils/Network'
import {
  testCandidateApiModelList1,
  testCandidateApiModelList2,
  testCandidateInactive,
  testCandidateList,
} from '@local/Utils/TestHelpers/candidateData'
import { CandidateListStatus } from '@local/AdvisorList/types'
import { rest } from 'msw'

export const candidateHandlers = [
  rest.get(`${API_URL}/clientmatch/candidatelists/123`, (_req, res, ctx) =>
    res(ctx.status(200), ctx.json(testCandidateList))
  ),
  rest.get(`${API_URL}/clientmatch/candidatelists/345`, (_req, res, ctx) =>
    res(ctx.status(200), ctx.json({ ...testCandidateList, candidates: [] }))
  ),
  rest.get(`${API_URL}/clientmatch/candidatelists/456`, (_req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        ...testCandidateList,
        status: CandidateListStatus.Expired,
      })
    )
  ),
  rest.get(`${API_URL}/clientmatch/candidatelists/567`, (_req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        ...testCandidateList,
        status: CandidateListStatus.Deactivated,
      })
    )
  ),
  rest.get(`${API_URL}/clientmatch/candidatelists/678`, (_req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        ...testCandidateList,
        status: CandidateListStatus.Active,
      })
    )
  ),
  rest.get(`${API_URL}/clientmatch/candidatelists/789`, (_req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        ...testCandidateList,
        status: CandidateListStatus.Active,
        activation: {
          expires: new Date(
            new Date().setDate(new Date().getDate() + 4)
          ).toISOString(),
        },
      })
    )
  ),
  rest.get(`${API_URL}/clientmatch/candidatelists/999`, (_req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        ...testCandidateList,
        status: CandidateListStatus.Active,
        candidates: [testCandidateInactive],
      })
    )
  ),
  rest.get(
    `${API_URL}/clientmatch/candidatelists/showBanner`,
    (_req, res, ctx) => {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)

      return res(
        ctx.status(200),
        ctx.json({
          ...testCandidateList,
          activation: {
            ...testCandidateList.activation,
            expires: tomorrow.toISOString(),
          },
          status: CandidateListStatus.Active,
          candidates: [testCandidateInactive],
        })
      )
    }
  ),
  rest.get(
    `${API_URL}/clientmatch/candidatelists/dontShowBanner`,
    (_req, res, ctx) => {
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)

      return res(
        ctx.status(200),
        ctx.json({
          ...testCandidateList,
          activation: {
            ...testCandidateList.activation,
            expires: yesterday.toISOString(),
          },
          status: CandidateListStatus.Expired,
          candidates: [testCandidateInactive],
        })
      )
    }
  ),
  rest.get(`${API_URL}/clientmatch/candidatelists/error`, (_req, res, ctx) =>
    res(ctx.status(401), ctx.json([]))
  ),
  rest.get(`${API_URL}/clientmatch/candidatelists`, (_req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([testCandidateApiModelList1, testCandidateApiModelList2])
    )
  ),
]
