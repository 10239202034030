import { DeepLocation, JobTitle } from '@local/Types/MatchingApi.types'

export const convertToEnglishLocationTypesCapitalStart = (type: string) => {
  switch (type.toLocaleLowerCase()) {
    case 'län':
      return 'County'
    case 'tätort':
      return 'City'
    case 'kommun':
      return 'Municipality'
    case 'land':
      return 'Country'
    default:
      return type
  }
}

export const convertToSwedishLocationTypes = (type: string) => {
  switch (type.toLocaleLowerCase()) {
    case 'county':
      return 'län'
    case 'city':
      return 'tätort'
    case 'municipality':
      return 'kommun'
    case 'country':
      return 'land'
    default:
      return type
  }
}

export const convertToSwedishTypesdRecursive = (
  deepLocation: DeepLocation
): DeepLocation => ({
  ...deepLocation,
  type: convertToSwedishLocationTypes(deepLocation.type),
  locatedIn: deepLocation.locatedIn
    ? convertToSwedishTypesdRecursive(deepLocation.locatedIn)
    : null,
})

export const convertToJobTitlesWithHighlight = (
  jobTitle: JobTitle
): JobTitle => ({
  ...jobTitle,
  name: jobTitle.jobTitleWithHighLightedSearchTerm,
})
export const convertToLocationsWithHighlight = (
  location: DeepLocation
): DeepLocation => ({
  ...location,
  name: location.nameWithHighLightedSearchTerm,
})
