import React, { useState } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { Button } from '@mui/material'
import ExternalLinkDialog from '@local/CandidateList/components/ExternalLink/ExternalLinkDialog'
import { CandidateList } from '@local/Types/MatchingApi.types'
import { useActivateExternalMutation } from '@local/AdvisorList'

interface DeactivateExternalLinkButtonProps {
  list: Pick<CandidateList, 'name' | 'id'>
}

const DeactivateExternalLinkButton: React.FunctionComponent<
  DeactivateExternalLinkButtonProps
> = ({ list }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { deactivateButton } = usePickEpiContent().candidateList.externalLink

  const [activateExternal] = useActivateExternalMutation()

  const handleToggleDialogOpen = () => {
    setIsDialogOpen((prevState) => !prevState)
  }

  const handleConfirm = () => {
    void activateExternal({ listId: list.id, activate: false })
    setIsDialogOpen(false)
  }

  return (
    <>
      <Button onClick={handleToggleDialogOpen} variant="outlined" size="small">
        {deactivateButton}
      </Button>
      {isDialogOpen && (
        <ExternalLinkDialog
          type="deactivate"
          listName={list.name}
          onCancel={handleToggleDialogOpen}
          onConfirm={handleConfirm}
        />
      )}
    </>
  )
}

export default DeactivateExternalLinkButton
