import { useGetCandidateListsQuery } from '@local/AdvisorList/api/CandidateListApi'
import AdvisorListItem from '@local/AdvisorList/components/AdvisorListItem/AdvisorListItem'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'
import React from 'react'
import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'

export const AdvisorLists: React.FunctionComponent = () => {
  const { title, body } = usePickEpiContent().minaListor.emptyResult
  const { data, isError, isLoading } = useGetCandidateListsQuery()

  if (isLoading) {
    return <CircularProgress />
  }

  if (!data?.length || isError) {
    return (
      <EmptyResult
        heading={title}
        description={body}
        data-testid="candidate-lists-empty-result"
        sx={{
          m: (theme) => theme.spacing(5, 2),
        }}
      />
    )
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {data?.map((list) => <AdvisorListItem list={list} key={list.id} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
