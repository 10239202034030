import React from 'react'
import { Delete } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'

interface RemoveListIconButtonProps {
  onClick: () => void
}

const RemoveListIconButton: React.FC<RemoveListIconButtonProps> = ({
  onClick,
}) => {
  const { removeList } = usePickEpiContent().candidateList.actions
  return (
    <Tooltip title={removeList}>
      <IconButton onClick={onClick} data-testid="candidate-list-delete">
        <Delete />
      </IconButton>
    </Tooltip>
  )
}

export default RemoveListIconButton
